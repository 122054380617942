<template>
   <!-- Login content -->
   <div>
      <div class="card-body">
         <p class="login-box-msg">Informe suas credenciais para entrar</p>
         <form @submit.prevent="loginUser">
            <div class="input-group mb-3">
               <input type="text" v-model="user.email"
                      class="form-control" placeholder="Email" required>
               <div class="input-group-append">
                  <div class="input-group-text">
                     <span class="fas fa-envelope"></span>
                  </div>
               </div>
            </div>
            <div class="input-group mb-3">
               <input type="password" v-model="user.pwd"
                      class="form-control" placeholder="Senha" required>
               <div class="input-group-append">
                  <div class="input-group-text">
                     <span class="fas fa-lock"></span>
                  </div>
               </div>
            </div>
            <div class="row">
               <!-- /.col -->
               <div class="col-12">
                  <button
                     type="submit"
                     class="btn btn-block btn-outline-primary"
                     :disabled="isLoggingIn">
                     <b>Entrar</b>
                  </button>
               </div>
               <!-- /.col -->
            </div>
         </form>

         <p class="mb-1 mt-4">
            <router-link :to="{name: 'forgotPassword'}" class="text-gray-dark">Esqueci a minha senha</router-link>
         </p>
         <p class="mb-1">
            <router-link :to="{name: 'register'}" class="text-gray-dark">Registre-se</router-link>
         </p>
      </div>
      <!-- /.card-body -->

   </div>

</template>

<script>
import { mapActions } from "vuex";
import { useToast } from "vue-toastification";

export default {
   name: 'Login',
   setup() {
      const toast = useToast()
      return { toast }
   },
   data(){
      return {
         user: {
            email: '',
            pwd: ''
         },
         isLoggingIn : false
      }
   },
   created() {
      document.querySelector('body').className = "hold-transition login-page"
   },
   methods: {
        ...mapActions(['login']),
        async loginUser () {
           this.isLoggingIn  = true;

           try {
              const isAllowed = await this.login({ email: this.user.email, pwd: this.user.pwd });
              if (!isAllowed){
                 this.toast.warning(this.$t("login_not_allowed"));
                 return
              }

              window.location.replace(this.$route.query.from ?? "/dashboard");
              this.toast.success(this.$t("login_success"));
              setTimeout(() => { window.location.reload(); }, 3000);
           } catch {
              this.toast.error(this.$t("login_error"));
           } finally {
              this.isLoggingIn  = false;
           }
        }
     }
}
</script>
